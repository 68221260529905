@tailwind base;

@tailwind components;

@tailwind utilities;

.rbc-month-row {
  display: inline-table !important;
  flex: 0 0 0 !important;
  min-height: 50px !important;
}